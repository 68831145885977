import React from "react"
import { useLocation } from "@reach/router"
import { MetaData } from "./MetaData"
import { Helmet } from "react-helmet"
import { Schema } from "./Schema"
import styled from "styled-components"
import { CannonicalGenerator } from "./canonical"

function Seo({ title, country, swiftcodes, swiftCanonical }) {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const isSg = location.pathname.includes("/sg")
  const isSendMoneyPage = location.pathname.includes("/send-money")
  const isHomePage = title === "homepage"

  const {
    titleEn = "",
    titleId = "",
    descEn = "",
    descId = "",
    keywordsEn = "",
    keywordsId = "",
  } = MetaData({ title, country, swiftcodes })

  const TitlePrice = isSendMoneyPage ? `price` : `priceRange`
  const PriceAmount = isSendMoneyPage
    ? `IDR ${country.price}`
    : `IDR 10,000 to IDR 125,000`

  const canonical = CannonicalGenerator({
    location,
    swiftCanonical,
    isHomePage,
    isEnglish,
  })

  const hrefLangEnUs = isEnglish
    ? `https://www.transfez.com${location.pathname}`
    : `https://www.transfez.com/en${location.pathname}`
  const hrefDefaultSplitted = location.pathname.split("/en").join("")
  const hrefDefaultLink = `https://www.transfez.com${hrefDefaultSplitted}`
  const ImageOpengraph = isSg
    ? `https://news.transfez.com/wp-content/uploads/2022/08/opengraph-sg-1024x576.jpg`
    : `https://news.transfez.com/wp-content/uploads/2022/08/opengraph-id-1024x576.jpg`

  const isStaging = process.env.GATSBY_LOCATION_NOW == "STAGING"
  const isProduction = process.env.GATSBY_LOCATION_NOW == "PRODUCTION"
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{isEnglish ? titleEn : titleId}</title>
        {descEn && (
          <meta name="description" content={isEnglish ? descEn : descId} />
        )}
        {keywordsEn && (
          <meta name="keywords" content={isEnglish ? keywordsEn : keywordsId} />
        )}
        <link rel="canonical" href={canonical} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={isEnglish ? titleEn : titleId} />
        <meta property="og:description" content={isEnglish ? descEn : descId} />
        <meta property="og:url" content={canonical} />
        <meta property="og:site_name" content="Transfez" />
        <meta property="og:image" content={ImageOpengraph} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={isEnglish ? titleEn : titleId} />
        <meta
          name="twitter:description"
          content={isEnglish ? descEn : descId}
        />
        <link rel="alternate" hrefLang="en-us" href={hrefLangEnUs} />
        {/* <link rel="alternate" hrefLang="x-default" href={hrefDefaultLink} /> */}
        <link rel="alternate" hrefLang="id-id" href={hrefDefaultLink} />
        {isStaging && <meta name="robots" content="noindex" />}
        {isStaging && <meta name="googlebot" content="noindex" />}
        {isProduction && (
          <meta
            name="google-site-verification"
            content="ZgoQRnignn0sjm2aI-5YZaxBRxCDkntCRwPLvNciwww"
          />
        )}
        <script type="application/ld+json">
          {`
        {
            "@context": "http://schema.org",
            "@type": "Organization",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "DKI Jakarta",
                "postalCode": "12870",
                "streetAddress": "Jl. Casablanca Raya Kav.88, Prudential Centre Lt. 22, RT.14/RW.5, Menteng Dalam, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12870",
                "addressRegion": "Jakarta Selatan"
            },
            "url": "https://www.transfez.com",
            "logo": "https://news.transfez.com/wp-content/uploads/2022/08/Icon-Rounded-Transfez-Logo.png",
            "name": "PT. Indo Koala Remittance",
            "sameAs": [ "https://www.facebook.com/Transfez.id/",
            "https://twitter.com/transfez",
            "https://www.instagram.com/transfez.id/",
            "https://www.youtube.com/channel/UCetpYhmtJh4JZj_bwaVo5vg",
            "https://www.linkedin.com/company/transfez/" ],
            "description": "Aplikasi Transfer Uang Antar Negara Terpercaya, Cepat, dan Aman",
            "email": "hello@transfez.com",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+6281299823838",
                "contactType": "Customer service"
            }
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "name": "PT. Indo Koala Remittance",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Jl. Casablanca Raya Kav.88, Prudential Centre Lt. 22, RT.14/RW.5, Menteng Dalam, Kota     Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12870",
                "addressLocality": "Jakarta Selatan",
                "addressRegion": "DKI Jakarta",
                "postalCode": "12870"
            },
            "telephone": "+62 (0)81299823838",
            "openingHoursSpecification" : {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": ["Monday","Tuesday","Wednesday","Thursday","Friday"],
                "opens": "08:59:59",
                "closes": "17:59:59"
            },
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "-6.2239881",
                "longitude": "106.8433827"
            },
            "url": "https://www.transfez.com",
            "logo": "https://news.transfez.com/wp-content/uploads/2022/08/Icon-Rounded-Transfez-Logo.png",
            "image": "https://news.transfez.com/wp-content/uploads/2022/08/Icon-Rounded-Transfez-Logo.png",
            "priceRange": "$",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.63",
                "bestRating": "5",
                "worstRating": "1",
                "ratingCount": "5206"
            }
        }
        `}
        </script>
        {isProduction && (
          <script type="application/javascript">
            {`
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '504623346858750');
  fbq('track', 'PageView');
`}
          </script>
        )}
        {isProduction && (
          <noscript>
            {`
  <img height="1" width="1" style="display:none" 
       src="https://www.facebook.com/tr?id={504623346858750}&ev=PageView&noscript=1"/>
        `}
          </noscript>
        )}
        {isProduction && (
          <script type="text/javascript">
            {`
		!function (w, d, t) {
		  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
		
		  ttq.load('C56MPJCK7EFNSJPVPVG0');
		  ttq.page();
		}(window, document, 'ttq');
        `}
          </script>
        )}
        {isProduction && (
          <script type="text/javascript">
            {`
_linkedin_partner_id = "3354900";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        `}
          </script>
        )}
        {isProduction && (
          <script type="text/javascript">
            {`
(function(l) {
if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
window.lintrk.q=[]}
var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})(window.lintrk);
        `}
          </script>
        )}
        {isProduction && (
          <noscript>
            {`
<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3354900&fmt=gif" />
        `}
          </noscript>
        )}
        {isProduction && (
          <script type="text/javascript">
            {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KLZSL7F');
        `}
          </script>
        )}
        {isProduction && (
          <script type="text/javascript">
            {`
            window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-152684683-1');
        `}
          </script>
        )}
        {isProduction && (
          <noscript>
            {`
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KLZSL7F"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `}
          </noscript>
        )}
      </Helmet>
      <Schema title={title} isEnglish={isEnglish} country={country} />
      <InvisibleH1>{isEnglish ? titleEn : titleId}</InvisibleH1>
    </>
  )
}

export default Seo

const InvisibleH1 = styled.h1`
  display: none;
`
